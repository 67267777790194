import moment from "moment";
import "moment-timezone";
export default class MilestoneDataModel {
  id: number;
  name: string;
  dueDate: string;
  actualDate: string;
  typeId: number;

  constructor(data) {
    if (data !== null) {
      this.id = data.id;
      this.name = data.name;
      this.dueDate = data.plannedDate
        ? moment.tz(data.plannedDate, data.timezone).format("YYYY-MM-DD")
        : null;
      this.actualDate = data.actualDate
        ? moment(data.actualDate).format("YYYY-MM-DD")
        : null;
      this.typeId = data.typeId;
    }
  }
}
