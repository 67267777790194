import { SortFilterModel } from "./SortFilterModel";
import { ChangeTypeFilterModel } from "./ChangeTypeFilterModel";
import { ChangesDateFilterModel } from "./ChangesDateFilterModel";

export class ChangesChosenFiltersModel {
  chosenProducts: string[] = [];
  changeTypeFilter: ChangeTypeFilterModel = new ChangeTypeFilterModel(null);
  chosenDistributionCenters: number[] = [];
  requestId: number = null;
  userId: number = null;
  dateFilter: ChangesDateFilterModel = new ChangesDateFilterModel(null);
  name: string;
  id: number;
  orderBy = "date";
  ascending: boolean;

  constructor(model: any) {
    if (model) {
      this.changeTypeFilter = new ChangeTypeFilterModel(model.changeTypeFilter);
      this.dateFilter = new ChangesDateFilterModel(model.dateFilter);
      this.chosenDistributionCenters = model.chosenDistributionCenters;
      this.chosenProducts = model.chosenProducts;
      this.name = model.name;
      this.id = model.id;
      this.requestId = model.requestId;
      this.userId = model.userId;
      this.orderBy = model.orderBy;
      this.ascending = model.ascending;
    }
  }
}
