


























































































































import { EditableSelect, EditableNumberInput} from "../shared/Shared.vue";
import { RequestCategory, OperationResultType } from "../../enums/enums";
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";
import ManufacturingInformationModel from "@/models/fulfilment-plan/ManufacturingInformationModel";
import { manufacturingCycleService } from "../../services/manufacturingcycle.service";

export default {
  components: {
    EditableSelect,
    EditableNumberInput
  },
  data() {
    return {
      model: new ManufacturingInformationModel(null),
      loadingManufacturingInformation: false,
      RequestCategory: RequestCategory,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getManufacturingInformation(this.$route.params.id);
    });
  },
  computed: {
    ...mapState("fulfilmentPlanDetails", [
      "timestamp",
      "canManageFulfilmentPlan",
      "manufacturingInformationRefresh",
      "editionLock",
    ]),
    holdResumeButtonText() {
      if (this.model.statusDescription === "On Hold") return "Resume";
      else return "On Hold";
    },
    showDataAvailability() {
      return !(
        (this.model.isInCBSScope && this.model.category === RequestCategory.eRequest) ||
        this.model.category === RequestCategory.ReOrder
      );
    }
  },
  watch: {
    manufacturingInformationRefresh: function () {
      if (this.manufacturingInformationRefresh) {
        this.getManufacturingInformation(this.model.id);
        this.setManufacturingInformationRefresh(false);
      }
    },
  },
  methods: {
    ...mapActions("fulfilmentPlanDetails", [
      "setEditionLock",
      "setManufacturingInformationRefresh",
      "storeTimestamp",
      "setChangesRefresh",
      "setMilestoneRefresh",
      "setRequestInformationRefresh",
    ]),
    getManufacturingInformation(id) {
      this.loadingManufacturingInformation = true;
      fulfilmentPlanDetailsService
        .getManufacturingInformation(id)
        .then((response) => {
          const data = response.data;
          this.model = new ManufacturingInformationModel(data);
          this.loadingManufacturingInformation = false;
        });
    },
    setManufacturingOnHoldResume() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .setManufacturingOnHoldResume(this.model.id, this.timestamp)
        .then(({ data }) => {
          if (data.result === 0) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Manufacturing status changed succesfully",
              "success"
            );
            this.setManufacturingInformationRefresh(true);
            this.setChangesRefresh(true);
          } else if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.setManufacturingInformationRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    rescheduleManufacturingCycle() {
      this.$confirm(
        "Completing this operation will reschedule pending Manufacturing Milestones and cannot be undone. Do you want to continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.setEditionLock(true);
        manufacturingCycleService
          .rescheduleManufacturingMilestones(this.model.id)
          .then(({ data }) => {
            if (data === 200) {
              this.storeTimestamp(data.timestamp);
              this.showNotification(
                "Manufacturing Milestones rescheduled succesfully",
                "success"
              );
              this.setChangesRefresh(true);
              this.setMilestoneRefresh(true);
              this.setManufacturingInformationRefresh(true);
            } else if (data === 500) {
              this.showNotification(data.message, "error");
              this.setManufacturingInformationRefresh(true);
            }
            this.setEditionLock(false);
          });
      });
    },
    setSides() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editSides(this.model.id, this.model.sides, this.timestamp)
        .then(({ data }) => {
          if (data.result === 0) {
            this.storeTimestamp(data.timestamp);
            this.showNotification("Sides changed successfully", "success");
            this.setManufacturingInformationRefresh(true);
          } else if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.setManufacturingInformationRefresh(true);
          }
          this.setChangesRefresh(true);
          this.setEditionLock(false);
        });
    },
    changeCustomBlank() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editCustomBlank(this.model.id, this.model.customBlank)
        .then(({ data }) => {
          if (data.result === 0) {
            this.showNotification(
              "Custom Blank changed successfully",
              "success"
            );
            this.setManufacturingInformationRefresh(true);
          } else if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.setManufacturingInformationRefresh(true);
          }
          this.setChangesRefresh(true);
          this.setEditionLock(false);
        });
    },
    editManufacturingLocalization(ignoreWarnings) {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editManufacturingLocalization(
          this.model.id,
          this.model.location,
          this.timestamp,
          ignoreWarnings
        )
        .then((response) => {
          const data = response.data;
          if (data.result === 0) {
            this.storeTimestamp(data.timestamp);
            this.showNotification("Location changed succesfully.", "success");
            this.setChangesRefresh(true);
          } else if (data.Result === 1) {
            this.openConfirmPopup(data.message, (confirmAction) => {
              this.editManufacturingLocalization(true);
            });
          } else if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.setManufacturingInformationRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editManufacturingDataRevision(value) {
      this.$confirm(
        "Do you really want to check Data Availability? This operation cannot be reverted.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.setEditionLock(true);
          fulfilmentPlanDetailsService
            .editManufacturingDataRevision(this.model.id, value, this.timestamp)
            .then(({ data }) => {
              if (data.result === 0) {
                this.storeTimestamp(data.timestamp);
                this.showNotification(
                  "Data Availability changed succesfully.",
                  "success"
                );
                this.setChangesRefresh(true);
                this.setMilestoneRefresh(true);
                this.setManufacturingInformationRefresh(true);
                this.setRequestInformationRefresh(true);
              } else if (data.result === 2) {
                this.showNotification(data.message, "error");
                this.setManufacturingInformationRefresh(true);
              }
              this.setEditionLock(false);
            });
        })
        .catch(() => {
          this.model.dataAvailability = false;
          this.$message({
            type: "info",
            message: "Operation cancelled.",
          });
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    openConfirmPopup(message, confirmAction) {
      this.$confirm(message, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          confirmAction();
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Action aborted.",
          });
        });
    },

    editManufacturingPosition() {
      this.$confirm(
        "Do you really want to change Manufacturing position?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
      .then(() => {
        this.setEditionLock(true);
        fulfilmentPlanDetailsService
          .editManufacturingStartDatePosition(this.model.id, this.model.manufacturingPosition)
          .then((response) => {
            if (response.data.result === OperationResultType.Error) {
              this.showNotification(response.data.message, "error");
            } else {
              this.showNotification(
                  "Manufacturing position changed succesfully.",
                  "success"
              );
              this.setChangesRefresh(true);
              this.setManufacturingInformationRefresh(true);
            }
          });
          this.setEditionLock(false);
      })
      .catch((e) => {
        this.$message({
          type: "info",
          message: "Operation cancelled.",
        });
      });
    }
  },
};
