import { HTTP } from "./HttpCommon";

class FulfilmentPlanDetailsService {
  getRequestConfiguration(id) {
    return HTTP.get(`FulfilmentPlan/GetDetailsConfiguration/` + id);
  }

  getPlanData(id) {
    return HTTP.get(`FulfilmentPlan/GetPlanData/` + id);
  }

  getRequestInformation(id) {
    return HTTP.get(`FulfilmentPlan/GetRequestInformation/` + id);
  }

  getLeadTimeModifications(id) {
    return HTTP.get(`FulfilmentPlan/GetLeadTimeModifications/` + id);
  }

  updateLeadTimeModifications(id, items) {
    return HTTP.post(`FulfilmentPlan/UpdateLeadTimeModifications/` + id, items);
  }

  getManufacturingInformation(id) {
    return HTTP.get(`FulfilmentPlan/GetManufacturingInformation/` + id);
  }

  getMilestones(id) {
    return HTTP.get(`FulfilmentPlan/GetMilestones/` + id);
  }

  getShowShipmentInformation(id) {
    return HTTP.get(`FulfilmentPlan/GetShowShipmentInformation/` + id);
  }

  getShipmentInformation(id) {
    return HTTP.get(`FulfilmentPlan/GetShipmentInformation/` + id);
  }

  editTrackingUrl(fulfilmentPlanId, trackingUrl) {
    return HTTP.post(`FulfilmentPlan/EditTrackingUrl`, {
      id: fulfilmentPlanId,
      newValue: trackingUrl,
    });
  }

  editTrackingNumber(fulfilmentPlanId, trackingNumber) {
    return HTTP.post(`FulfilmentPlan/EditTrackingNumber`, {
      id: fulfilmentPlanId,
      newValue: trackingNumber,
    });
  }

  getComments(id) {
    return HTTP.get(`FulfilmentPlan/GetComments/` + id);
  }

  getTimeline(id) {
    return HTTP.get(`FulfilmentPlan/GetFulfilmentPlanTimeline/` + id);
  }

  addComment(fulfilmentPlanId, comment, success, error) {
    HTTP.post(`FulfilmentPlan/AddComment`, {
      fulfilmentPlanId: fulfilmentPlanId,
      commentText: comment,
    })
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        error(e);
      });
  }

  editComment(fulfilmentPlanId, commentId, comment, success, error) {
    HTTP.post(`FulfilmentPlan/EditComment`, {
      fulfilmentPlanId: fulfilmentPlanId,
      commentId: commentId,
      commentText: comment,
    })
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        error(e);
      });
  }

  deleteComment(commentId, success, error) {
    HTTP.post(`FulfilmentPlan/DeleteComment/${commentId}`)
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        error(e);
      });
  }

  getChanges(id, success, error) {
    HTTP.get(`FulfilmentPlan/GetChanges/` + id).then((response) => {
      success(response.data);
    });
  }

  getLinkedPlans(id) {
    return HTTP.get(`FulfilmentPlan/GetLinkedPlans/` + id);
  }

  getLinkTypes() {
    return HTTP.get(`FulfilmentPlan/GetLinkTypes`);
  }

  saveLink(fulfilmentPlanId, linkedRequestId, linkType, comment) {
    return HTTP.post(`FulfilmentPlan/SaveLink`, {
      fulfilmentPlanId: fulfilmentPlanId,
      linkedRequestId: linkedRequestId,
      linkType: linkType,
      comment: comment,
    });
  }

  deleteLink(fulfilmentPlanId, linkId) {
    return HTTP.post(`FulfilmentPlan/DeleteLink`, {
      fulfilmentPlanId: fulfilmentPlanId,
      linkId: linkId,
    });
  }

  filterRequests(query) {
    return HTTP.get(`FulfilmentPlan/FilterRequests?containsString=` + query);
  }

  editQuantity(id, value, timestamp) {
    return HTTP.post(`FulfilmentPlan/EditQuantity/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
    });
  }

  editProductNumber(id, value, timestamp, ignoreWarning) {
    return HTTP.post(`FulfilmentPlan/EditProductNumber/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
      ignoreWarning: ignoreWarning,
    });
  }

  editSides(id, value, timestamp) {
    return HTTP.post(`FulfilmentPlan/EditSides`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
    });
  }

  editManufacturingLocalization(id, value, timestamp, ignoreWarning) {
    return HTTP.post(`FulfilmentPlan/EditManufacturingLocalization/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
      ignoreWarning: ignoreWarning,
    });
  }

  editDistributionCenter(id, value, timestamp, ignoreWarning) {
    return HTTP.post(`FulfilmentPlan/EditDistributionCenter/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
      ignoreWarning: ignoreWarning,
    });
  }

  editRequestStatus(id, value, timestamp, ignoreWarning) {
    return HTTP.post(`FulfilmentPlan/editRequestStatus/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
      ignoreWarning: ignoreWarning,
    });
  }

  editManufacturingAtRisk(id, value, timestamp) {
    return HTTP.post(`FulfilmentPlan/EditManufacturingAtRisk/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
    });
  }

  editPriority(id, value, timestamp) {
    return HTTP.post(`FulfilmentPlan/EditIsPriority/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
    });
  }

  editPONumber(id, value, timestamp) {
    return HTTP.post(`FulfilmentPlan/EditPONumber/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
    });
  }

  editPONumberDate(id, value, timestamp) {
    return HTTP.post(`FulfilmentPlan/EditPONumberDate/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
    });
  }

  editOrderNumber(id, value, timestamp, ignoreWarning) {
    return HTTP.post(`FulfilmentPlan/EditOrderNumber/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
      ignoreWarning: ignoreWarning,
    });
  }

  editManufacturingDataRevision(id, value, timestamp) {
    return HTTP.post(`FulfilmentPlan/EditManufacturingDataRevision/`, {
      id: id,
      newValue: value,
      timestampBase64: timestamp,
    });
  }

  editDesignSessionDate(id, value, timestamp, ignoreWarning, timeZoneOffset) {
    return HTTP.post(`FulfilmentPlan/EditDesignSessionDate/`, {
      id,
      newValue: value,
      timestampBase64: timestamp,
      ignoreWarning: ignoreWarning,
      timeZoneOffset: timeZoneOffset,
    });
  }

  editFileUploadedDate(id, value, timestamp, timeZoneOffset) {
    return HTTP.post(`FulfilmentPlan/EditFileUploadedDate`, {
      id,
      newValue: value,
      timestampBase64: timestamp,
      timeZoneOffset: timeZoneOffset,
    });
  }

  setManufacturingOnHoldResume(id, timestamp) {
    return HTTP.post(`FulfilmentPlan/SetManufacturingOnHoldResume`, {
      id: id,
      timestampBase64: timestamp,
    });
  }

  getDetailsContext(id) {
    return HTTP.get(`FulfilmentPlan/GetContext/` + id);
  }

  editMilestonePlannedDate(
    fulfilmentPlanId,
    milestoneId,
    value,
    timestamp,
    ignoreWarning,
    timeZoneOffset
  ) {
    return HTTP.post(`FulfilmentPlan/EditMilestonePlannedDate/`, {
      id: fulfilmentPlanId,
      milestoneId: milestoneId,
      newValue: value,
      timestampBase64: timestamp,
      ignoreWarning: ignoreWarning,
      timeZoneOffset: timeZoneOffset,
    });
  }

  unlockMilestones(fulfilmentPlanId) {
    return HTTP.post(`FulfilmentPlan/UnlockMilestones/${fulfilmentPlanId}`);
  }

  editMilestoneActualDate(
    fulfilmentPlanId,
    milestoneId,
    value,
    timestamp,
    ignoreWarning,
    timeZoneOffset
  ) {
    return HTTP.post(`FulfilmentPlan/EditMilestoneActualDate/`, {
      id: fulfilmentPlanId,
      milestoneId: milestoneId,
      newValue: value,
      timestampBase64: timestamp,
      ignoreWarning: ignoreWarning,
      timeZoneOffset: timeZoneOffset,
    });
  }

  editOverflowDays(fulfilmentPlanId, days) {
    return HTTP.post("FulfilmentPlan/EditOverflowDays", {
      id: fulfilmentPlanId,
      newValue: days,
    });
  }

  editRaApproval(fulfilmentPlanId, raApproval, timestamp) {
    return HTTP.post("FulfilmentPlan/EditRaApproval", {
      id: fulfilmentPlanId,
      newValue: raApproval,
      timestampBase64: timestamp,
    });
  }

  editLoanerSet(fulfilmentPlanId, loanerSet) {
    return HTTP.post("FulfilmentPlan/EditLoanerSet", {
      id: fulfilmentPlanId,
      newValue: loanerSet,
    });
  }

  editCustomBlank(fulfilmentPlanId, customBlank) {
    return HTTP.post(`FulfilmentPlan/EditCustomBlank`, {
      id: fulfilmentPlanId,
      newValue: customBlank,
    });
  }

  reimportRequest(fulfilmentPlanId, reimportRequest) {
    return HTTP.post(`FulfilmentPlan/UpdateReimport`, {
      id: fulfilmentPlanId,
      newValue: reimportRequest,
    });
  }

  addOrEditManufacturingStartDate(fulfilmentPlanId, manufacturingStartDate, timeZoneOffset) {
    return HTTP.post(`FulfilmentPlan/AddOrEditManufacturingStartDate`, {
      id: fulfilmentPlanId,
      newValue: manufacturingStartDate,
      timeZoneOffset : timeZoneOffset
    });
  }

  editManufacturingStartDatePosition(fulfilmentPlanId, position) {
    return HTTP.post(`FulfilmentPlan/EditManufacturingStartDatePosition`, {
      id: fulfilmentPlanId,
      newValue: position
    });
  }

  recalculateManufacturingStartDate(productTypeCode) {
    return HTTP.post(`FulfilmentPlan/RecalculateManufacturingStartDate/`, {
      newValue: productTypeCode
    });
  }
}

export const fulfilmentPlanDetailsService = new FulfilmentPlanDetailsService();
