<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <el-col :span="1" :push="23">
        <el-button id="addButton" @click="handleAddClick">Add</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table :data="items" style="width: 100%">
        <el-table-column
          prop="milestone"
          sortable
          label="Milestone"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productType"
          sortable
          label="Product Type"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="additionalDays"
          sortable
          label="Additional Days"
          align="center"
        ></el-table-column>
        <el-table-column width="250">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEditClick(scope.$index, scope.row)"
              >Edit</el-button
            >
            <el-button
              v-if="!scope.row.IsDefault"
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog
      id="addOrEditDialog"
      :visible.sync="addOrEditDialogVisible"
      :title="dialogOperationTitle"
      width="30%"
    >
      <el-form label-width="45%" :model="modal.currentMilestoneLeadTime">
        <el-form-item label="Product Type">
          <el-select v-model="modal.productTypeId" placeholder="None">
            <el-option
              v-for="item in productTypes"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Milestone">
          <el-select v-model="modal.milestoneId" placeholder="None">
            <el-option
              v-for="item in milestones"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Additional Days">
          <el-input-number
            v-model="modal.additionalDays"
            :min="1"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelDialog">Cancel</el-button>
        <el-button type="primary" @click="handleAddOrEdit">{{
          dialogOperation
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";

import { leadTimeModificationsService } from "../../services/leadtimemodifications.service";

export default Vue.extend({
  created() {
    Promise.all([
      leadTimeModificationsService.getProductTypes(),
      leadTimeModificationsService.getMilestones(),
    ]).then((values) => {
      this.productTypes = values[0].data;
      this.milestones = values[1].data;

      this.getModificationLeadTimes();
    });
  },
  data() {
    return {
      items: [],
      productTypes: [],
      milestones: [],
      modal: {
        id: null,
        additionalDays: 0,
        milestoneId: null,
        productTypeId: null,
      },
      loading: false,
      addOrEditDialogVisible: false,
    };
  },
  computed: {
    dialogOperation: function () {
      return this.modal.id !== null ? "Apply" : "Add";
    },
    dialogOperationTitle: function () {
      return this.modal.id !== null ? "Edit" : "Add";
    },
  },
  methods: {
    handleAddClick() {
      this.clearModal();
      this.isEdit = false;
      this.addOrEditDialogVisible = true;
    },
    handleCancelDialog() {
      this.addOrEditDialogVisible = false;
    },
    handleEditClick(index, row) {
      this.modal = _.clone(this.items.find((x) => x.id === row.id));
      this.addOrEditDialogVisible = true;
    },
    clearModal() {
      this.modal = {
        id: null,
        additionalDays: 0,
        milestoneId: null,
        productTypeId: null,
      };
    },
    handleAddOrEdit() {
      if (this.modal.id !== null) {
        leadTimeModificationsService
          .updateLeadTimeModification(this.modal)
          .then((response) => {
            const result = response.data;
            if (result.message.length > 0) {
              this.$alert(result.Message.join(", "), "ERROR", {
                type: "error",
              });
            } else {
              this.addOrEditDialogVisible = false;
              this.clearModal();
              this.loading = true;
              this.getModificationLeadTimes().then(() => {
                this.loading = false;
              });
            }
          });
      } else {
        leadTimeModificationsService
          .addLeadTimeModification(this.modal)
          .then((response) => {
            const result = response.data;
            if (result.message.length > 0) {
              this.$alert(result.message.join(", "), "ERROR", {
                type: "error",
              });
            } else {
              this.addOrEditDialogVisible = false;
              this.clearModal();
              this.loading = true;
              this.getModificationLeadTimes().then(() => {
                this.loading = false;
              });
            }
          });
      }
    },
    handleDelete(index, row) {
      this.$confirm(
        "This will permanently delete Lead Time Modifications. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        leadTimeModificationsService
          .deleteLeadTimeModification(row.id)
          .then(() => {
            this.loading = true;
            this.getModificationLeadTimes().then(() => {
              this.loading = false;
            });
          });
      });
    },
    getModificationLeadTimes() {
      return leadTimeModificationsService
        .getLeadTimeModifications()
        .then((response) => {
          this.items = response.data;
        });
    },
  },
});
</script>
